import companyConstant from '@/constants/company';

export default {
  messages: {
    tw: {
      wrongMes: '糟糕!你所尋找的網址已不存在!!',
      callingHelp: '如有疑問，歡迎來電' + companyConstant.INFO.CONTACT_PHONE,
    },
    en: {
      wrongMes: 'Oops! The URL you are looking for no longer exists!!',
      callingHelp: 'If in doubt, call' + companyConstant.INFO.CONTACT_PHONE,
    },
  },
};
