<template lang="pug">
  div.text-center
    img.m-5(src="@/assets/no-contract.png")
    h3.text-secondary
      | {{ $t('wrongMes') }}
      br
      | {{ $t('callingHelp') }}
</template>

<script>
import i18n from './lang';
export default {
  name: 'ErrorPage',

  i18n,
};
</script>
